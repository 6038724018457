import styled from "@emotion/styled";
import { Box, Paper, useMediaQuery } from "@mui/material";

export const StyledBox = styled(Box)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

export const StyledPaper = styled(Paper)`
  width: ${({ theme }) =>
    useMediaQuery(theme.breakpoints.down("sm")) ? "100%" : "100%"};
  height: ${({ theme }) =>
    useMediaQuery(theme.breakpoints.down("sm")) ? "100vh" : "100%"};
  padding: ${({ theme }) =>
    useMediaQuery(theme.breakpoints.down("sm")) ? "0" : "1rem"};
  margin-top: ${({ theme }) =>
    useMediaQuery(theme.breakpoints.down("sm")) ? "0" : "4rem"};
  padding-top: 1rem;
`;
