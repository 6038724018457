import React from "react";
import { useTheme, useMediaQuery, Container } from "@mui/material";
import Resume from "./Resume";
import * as S from "./App.styles.jsx";

const App = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <S.StyledBox>
      {!!isMobile ? (
        <Resume />
      ) : (
        <Container
          sx={{
            width: "37.5rem",
          }}
        >
          <Resume />
        </Container>
      )}
    </S.StyledBox>
  );
};

export default App;
