import React from "react";
import {
  useTheme,
  useMediaQuery,
  Avatar,
  Box,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import * as S from "./App.styles";

const Resume = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <S.StyledPaper elevation={isMobile ? 0 : 1}>
      <Box display="flex" justifyContent="center" p={1} mb={1}>
        <Avatar
          src="profile-picture.jpg"
          alt="Ethan Collins"
          sx={{ width: "17rem", height: "17rem" }}
        />
      </Box>
      <Stack display="flex" alignItems="center">
        <Typography variant="h3" component="h2">
          Ethan Collins
        </Typography>
        <Typography variant="h5" component="p" fontWeight="400" mb={3}>
          Technology Consultant
        </Typography>
        <Typography
          variant="p"
          component="p"
          fontSize="1.25rem"
          fontWeight="400"
          p={1}
        >
          I'm a consultant with 3 years in the tech industry. Over the past two
          years, I've led frontend and backend teams for well-known companies in
          the Automotive and Oil & Gas sectors. I'm comfortable navigating
          fast-paced projects and delivering results, even when the full picture
          isn't in view. Clear communication, understanding project needs, and
          solid sprint planning are my strong suits. Technically, I'm skilled
          with React, Node.js, and AWS.
        </Typography>
        <IconButton
          color="primary"
          onClick={() =>
            window.open("https://www.linkedin.com/in/ethan-t-collins", "_blank")
          }
        >
          <LinkedInIcon fontSize="large" />
        </IconButton>
      </Stack>
    </S.StyledPaper>
  );
};

export default Resume;
